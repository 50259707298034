
import './bootstrap.js'
import './slider.js'
import '../css/app.css';

import animateScrollTo from 'animated-scroll-to';

window.animateScrollTo = animateScrollTo;

import { flare } from "@flareapp/js";

if (import.meta.env.PROD) {
    flare.light();
}
